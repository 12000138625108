import React from "react";
import Section from "../Section";
import { useTranslation } from "react-i18next";

const NextDay: React.FC = () => {
  const { t } = useTranslation();

  const sectionStyle = {
    backgroundColor: "var(--sand)",
  };

  const content = (
    <div className="section-flexbox">
      <div className="section-left section-text">
        <h2 className="section-title">{t("section.nextDay.heading")}</h2>
        <p>{t("section.nextDay.paragraphOne")}</p>
      </div>
      <div className="section-right section-image">
        <img src="/images/next-day.png" alt="Next day" />
      </div>
    </div>
  );

  return <Section id="nextDay" content={content} style={sectionStyle} />;
};

export default NextDay;
